"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onOpenDocumentationGatewayPaymentGbc = exports.onOpenDocumentationTPPGbc = exports.onOpenWhatsAppContactGbc = exports.openUrlWithBlank = void 0;
function openUrlWithBlank(path) {
    window.open(path, '_blank');
}
exports.openUrlWithBlank = openUrlWithBlank;
function onOpenWhatsAppContactGbc(message) {
    var url = "https://wa.me/+595991719649?text=".concat(message !== null && message !== void 0 ? message : 'Hola tengo una consulta', ", ");
    openUrlWithBlank(url);
}
exports.onOpenWhatsAppContactGbc = onOpenWhatsAppContactGbc;
function onOpenDocumentationTPPGbc() {
    openUrlWithBlank('https://docs.globalbridgeconnections.com/');
}
exports.onOpenDocumentationTPPGbc = onOpenDocumentationTPPGbc;
function onOpenDocumentationGatewayPaymentGbc() {
    openUrlWithBlank('https://gbc.gitbook.io/pasarela-docs/');
}
exports.onOpenDocumentationGatewayPaymentGbc = onOpenDocumentationGatewayPaymentGbc;
